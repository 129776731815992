// import server from '../lib/server.js'

import PouchDB, {
  STREAM_PATH as PATH
} from '../lib/PouchDB';

export const SESSION = 'SESSION';

let session_timer = null;

export const setSession = (_callback) => {
  const _db = new PouchDB(`${PATH}dummy`, {
    skip_setup: true
  });

  return (dispatch) => {
    if (!!session_timer) {
      clearInterval(session_timer);
    }

    const poll_session = () => {
      _db.getSession().then((response) => {
        // console.log(response);
        if (_callback) {
          _callback();
        }

        dispatch({
          type: SESSION,
          payload: !!response.userCtx && !!response.userCtx.name ? response.userCtx : null
        });
      });
    };

    session_timer = setInterval(poll_session, 5000);
    poll_session();
  }
}

const sessions = (state = null, action) => {
  if (!!action) {
    switch (action.type) {
      case SESSION:
        return action.payload;
      default:
        return state
    }
  }
};
export default sessions;
