

module.exports = {
  toolbar: "rgb(32,36,42)", // 40,44,52
  chart_background: 'rgb(19,23,34)',
  chart_text: 'rgb(217,217,217)',
  chart_grid: 'rgba(54,61,78, 0.85)',
  watermark: 'rgba(249, 240, 163, 0.1)'
  // 225,191,144
};
