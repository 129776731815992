import React, {Component} from 'react';

import PropTypes from 'prop-types';

import withWidth, {isWidthUp, isWidthDown} from '@material-ui/core/withWidth';
import {withStyles, withTheme} from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core';
import {ToggleButtonGroup, ToggleButton} from '@material-ui/lab';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import {createChart, LineStyle, CrosshairMode} from 'lightweight-charts';
import _ from "underscore";

import QueryString from "query-string";

import Binder from "../../lib/Binder";
import Colours from "../../lib/Colors";

import {startPolling as startPricePolling} from "../../models/Price";
import {startPolling as startPositionsPolling} from "../../models/Positions";
import {startPolling as startCandlesticksPolling} from "../../models/Candlesticks";

const TYPES = ["candlesticks", "heikins", "blocks"];

class ChartPage extends Component {
  constructor() {
    super()

    this.state = {
      // price: "n/a",
      loaded: false,
      filters: ["active", "validated", "averages", "trades"] // , "trades"]
    };

    this.loading_cache = [];

    this.initiate_chart = this.initiate_chart.bind(this);
    this.set_positions = this.set_positions.bind(this);

  }

  set_positions(forced) {
    const qs = QueryString.parse(this.props.location.search);

    const {positions: posishes} = this.props;
    const {filters} = this.state;

    const _averages = filters.indexOf("averages") > -1;
    const _trades = filters.indexOf("trades") > -1;

    const positions = _.map(_.filter(_.values(posishes), (p) => {
      let go_ahead = true;

      const _active = filters.indexOf("active") > -1;
      const _validated = filters.indexOf("validated") > -1;
      if (_active && !p.active) {
        go_ahead = false;
      }
      if (p.type !== "position" && (_validated && !p.properties.validated)) {
        go_ahead = false;
      }

      if (!!qs.configuration && qs.configuration !== "" && p.configuration !== qs.configuration) {
        go_ahead = false
      }


      return go_ahead;
    }), (pos) => {
      const {
        reference_id,
        reference_price,
        active,
        properties,
        positive,
        triggered_prices,
        close_date,
        close_price,
        cancelled,
        avg_price,
        type
      } = pos;
      return {
        t: parseInt(_.last((
          pos.type === "position"
          ? pos._id
          : reference_id).split("-")), 10) / 1000,
        p: close_price || reference_price,
        reference_id: pos.type === "position"
          ? pos._id
          : reference_id,
        reference_price,
        active,
        avg_price,
        properties: {
          validated: pos.type === "position" || properties.validated
        },
        positive,
        triggered_prices: pos.type === "position"
          ? _.map(pos.trades, (t) => {
            return [
              t.price,
              new Date(parseInt(t.ref.split("_")[1])),
              t.size
            ]
          })
          : _.values(triggered_prices),
        close_date,
        cancelled,
        type
      }
    });

    if (_.isEqual(positions, this.positions) && !forced) {
      // alert("equal");
      return;
    } else {
      // alert("not equal");
    }

    this.positions = positions;

    this.markers = [];

    if (!!this.price_lines) {
      _.each(_.values(this.price_lines), (pl) => {
        // this.candlestickSeries.removePriceLine(pl);
        pl.setMarkers([]);
        this.chart.removeSeries(pl);
      });
    }

    this.price_lines = {};
    // this.avg_prices = {}

    _.each(positions, pos => {
      // pos.t = parseInt(_.last(pos.reference_id.split("-")), 10) / 1000;
      // pos.p = pos.reference_price

      // this.markers[pos.t] = {
      //   time: pos.t,
      //   position: 'aboveBar',
      //   color: pos.positive ? 'green' : "red",
      //   shape: 'arrowDown',
      //   id: pos.key
      // };

      this.price_lines[pos.p] = this.price_lines[pos.p] || this.chart.addLineSeries({
        color: pos.active
          ? (
            pos.properties.validated
            ? (
              pos.positive
              ? 'red'
              : "lime")
            : (
              pos.positive
              ? 'maroon'
              : "green"))
          : "gray",
        lineStyle: LineStyle.Dotted,
        lineWidth: 1,
        crosshairMarkerVisible: false,
        crosshairMarkerRadius: 6,
        lineType: 1,
        lastValueVisible: false,
        priceLineVisible: false,
        priceScaleId: 'right'
      });

      if (!!pos.active && !!pos.avg_price && !!_averages) {
        this.price_lines[pos.p].createPriceLine({
          price: pos.avg_price,
          color: pos.positive
            ? 'lime'
            : "red",
          lineWidth: 2,
          lineStyle: LineStyle.Dashed,
          axisLabelVisible: pos.type !== "position"
        });
      }

      let matching_stick_open = _.first(this.props.candlesticks);
      let matching_stick_close = _.last(this.props.candlesticks);

      const triggered_prices = _.map(pos.triggered_prices, (tp) => {
        const positive = (
          !!tp[2]
          ? tp[2] > 0
          : pos.positive);
        return {
          t: Date.parse(tp[1]) / 1000,
          // matching_stick: _.first(this.props.candlesticks),
          time: matching_stick_open.time,
          position: positive
            ? 'belowBar'
            : 'aboveBar',
          color: positive
            ? 'green'
            : "red",
          shape: positive
            ? 'arrowUp'
            : 'arrowDown',
          id: tp[0]
        };
      });

      _.each(this.props.candlesticks, (c) => {
        if (Math.abs(pos.t - matching_stick_open.time) > Math.abs(pos.t - c.time)) {
          matching_stick_open = c;
        }

        if (!!pos.close_date && Math.abs(pos.close_date / 1000 - matching_stick_close.time) > Math.abs(pos.close_date / 1000 - c.time)) {
          matching_stick_close = c;
        }

        for (let i = 0; i < triggered_prices.length; i++) {
          if (Math.abs(triggered_prices[i].t - triggered_prices[i].time) > Math.abs(triggered_prices[i].t - c.time)) {
            // triggered_prices[i].matching_stick = c;
            triggered_prices[i].time = c.time;
          }
        }
      });

      if (!!_trades) { // !!pos.active &&
        this.markers = [
          ...this.markers,
          ...triggered_prices
        ];
      }

      if (pos.type !== "position") {
        this.price_lines[pos.p].setData([
          {
            time: matching_stick_open.time,
            value: pos.p
          }, {
            time: matching_stick_close.time,
            value: pos.p
          }
        ]);
      } else {
        this.price_lines[pos.p].setData([
          {
            time: matching_stick_close.time,
            value: pos.p
          }
        ]);
      }

      // this.candlestickSeries.setMarkers(this.markers)
      // this.candlestickSeries.createPriceLine({price: pos.p, color: 'green', lineWidth: 2, lineStyle: LineStyle.Dotted, axisLabelVisible: true});

    });

    this.candlestickSeries.setMarkers(_.sortBy(this.markers, 'time'));
  }

  initiate_chart() {
    const qs = QueryString.parse(this.props.location.search);

    const {
      databases,
      session,
      startPricePolling,
      startPositionsPolling,
      startCandlesticksPolling,
      price,
      positions,
      candlesticks
    } = this.props;

    const {type, exchange, pair, time} = this.props.match.params;

    if (TYPES.indexOf(type) === -1) {
      alert("wrong type");
      return;
    }

    if (!!this.stick_listener) {
      this.stick_listener.cancel();
    }

    if (!!this.candlestickSeries) {
      this.chart.removeSeries(this.candlestickSeries);
      this.candlestickSeries = null;
      if (!!this.priceLine) {
        this.priceLine = null;
      }
    }

    this.candlestickSeries = this.chart.addCandlestickSeries({priceScaleId: 'right', priceLineVisible: false, lastValueVisible: false});

    this.priceLine = this.candlestickSeries.createPriceLine({price, color: 'gold', lineWidth: 0.5, lineStyle: LineStyle.Dotted, axisLabelVisible: true});

    startPricePolling({exchange, pair});

    startPositionsPolling({
      exchange,
      pair,
      investor: qs.investor || (
        session.roles.indexOf("_admin") > -1
        ? null
        : session.name)
    });

    startCandlesticksPolling({exchange, pair, time, type});

    // set data
    this.candlestickSeries.setData(candlesticks || []);

    if (candlesticks && candlesticks.length > 0) {
      this.set_positions();
    }

  }

  componentDidMount() {
    const {windowWidth, windowHeight, databases, session} = this.props;
    const {type, exchange, pair, time} = this.props.match.params;
    const qs = QueryString.parse(this.props.location.search);
    const {mobile, active, validated, averages, trades} = qs;

    this.state.filters = _.compact(_.map(this.state.filters, (f) => {
      if (!_.has(qs, f) || !!parseInt(qs[f], 10)) {
        return f;
      }
      return null;
    }));

    this.chart = createChart(this.container, {
      width: windowWidth,
      height: windowHeight - (
        !mobile
        ? 64
        : 0),
      layout: {
        backgroundColor: Colours.chart_background,
        textColor: Colours.chart_text,
        fontSize: 12,
        // fontFamily: 'Calibri',
      },
      grid: {
        vertLines: {
          color: Colours.chart_grid,
          style: 1,
          visible: true
        },
        horzLines: {
          color: Colours.chart_grid,
          style: 1,
          visible: true
        }
      },
      crosshair: {
        mode: session.roles.indexOf("_admin") > -1
          ? CrosshairMode.Normal
          : CrosshairMode.Magnet
      },
      watermark: {
        color: Colours.watermark,
        visible: true,
        text: `KaiBot Charts - ${session.name} - ${exchange} - ${pair}`,
        fontSize: 16,
        horzAlign: 'left',
        vertAlign: 'bottom'
      },
      localization: {
        timeFormatter: (timestamp) => {
          // console.log(new Date(parseInt(timestamp * 1000, 10)));
          const stamp = new Date(parseInt(timestamp * 1000, 10));
          return stamp.toString();
        },
        dateFormat: 'dd/MM/yyyy'
      }
    });

    this.chart.subscribeCrosshairMove((param) => {
      // if (!!param.hoveredSeries) {
      //   console.log(param.hoveredSeries);
      // }
      if (!!param.hoveredMarkerId) {
        console.log(param.hoveredMarkerId);
      }
    });

    this.chart.subscribeClick((param) => {
      // if (!!param.hoveredSeries) {
      //   console.log(param.hoveredSeries);
      // }
      if (!!param.hoveredMarkerId) {
        console.log(param.hoveredMarkerId);
      }
    });

    this.initiate_chart();

  }

  componentWillUnmount() {
    if (!!this.chart) {
      this.chart.remove();
      this.chart = null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {exchange, pair} = this.props.match.params;
    const qs = QueryString.parse(this.props.location.search);
    const {mobile, active, validated, averages, trades} = qs;

    if (this.props.windowHeight !== prevProps.windowHeight || this.props.windowWidth !== prevProps.windowWidth) {
      this.chart.resize(this.props.windowWidth, this.props.windowHeight - (
        !mobile
        ? 64
        : 0));
    }

    if (!_.isEqual(this.props.match.params, prevProps.match.params)) {
      this.initiate_chart();
    }

    if (!_.isEqual(this.props.price, prevProps.price)) {
      if (!!this.priceLine) {
        this.priceLine.applyOptions({price: this.props.price});
      }
    }

    if (!_.isEqual(qs, QueryString.parse(prevProps.location.search))) {
      this.setState({
        filters: _.compact(_.map(this.state.filters, (f) => {
          if (!_.has(qs, f) || !!parseInt(qs[f], 10)) {
            return f;
          }
          return null;
        }))
      });
    }

    if (!_.isEqual(this.props.candlesticks, prevProps.candlesticks) || this.props.candlesticks_loading !== prevProps.candlesticks_loading) {
      if (this.set_position_timeout) {
        clearTimeout(this.set_position_timeout);
      }
      if ((this.props.candlesticks.length > 0 && prevProps.candlesticks.length === 0) || this.props.candlesticks_loading !== prevProps.candlesticks_loading) {

        this.candlestickSeries.setData(this.props.candlesticks);
        this.chart.timeScale().fitContent();
        this.set_positions();
      } else {
        // this.candlestickSeries.setData(this.props.candlesticks);
        // this.chart.timeScale().fitContent();
        const diff = _.difference(this.props.candlesticks, prevProps.candlesticks);
        if (diff.length === 1 || !this.props.candlesticks_loading) {
          _.each(diff, (candlestick) => this.candlestickSeries.update(candlestick));

        }
        this.set_position_timeout = setTimeout(() => {
          this.set_positions(true);
        }, 3000);

      }
    }

    if (!_.isEqual(this.props.positions, prevProps.positions)) {
      if (!!this.props.candlesticks && this.props.candlesticks.length > 0) {
        this.set_positions();
      }
    }

    if (!_.isEqual(this.state.filters, prevState.filters)) {
      this.set_positions(true);
    }

  }

  render() {
    const {classes, session} = this.props;

    const {type, exchange, pair, time} = this.props.match.params;

    const {mobile} = QueryString.parse(this.props.location.search);

    const admin = session.roles.indexOf("_admin") > -1;

    // <Typography variant="h6" className={classes.title} style={{
    //     marginLeft: 15
    //   }}>{`${session.name} - ${exchange} - ${pair}`}</Typography>

    return (<React.Fragment>
      {
        !mobile && (<AppBar position="static">
          <Toolbar className={classes.toolbar}>
            <div className={classes.header_title}>
              <img src="/KaiBot_small.png" alt="KaiBot" height={25} width={25}/> {
                this.props.candlesticks_loading && (<React.Fragment>

                  <Typography style={{
                      marginLeft: 15
                    }}>{"Loading history..."}</Typography>
                  <CircularProgress color="white" style={{
                      marginLeft: 15
                    }} size={20}></CircularProgress>
                </React.Fragment>)
              }
            </div>

            <ToggleButtonGroup value={this.state.filters} onChange={(e, filters) => this.setState({filters})} color="primary" aria-label="outlined primary button group">
              <ToggleButton value={"active"}>Active</ToggleButton>
              {!!admin && (<ToggleButton value={"validated"}>Validated</ToggleButton>)}
              <ToggleButton value={"averages"}>B/E Levels</ToggleButton>
              <ToggleButton value={"trades"}>Trades</ToggleButton>
            </ToggleButtonGroup>

          </Toolbar>
        </AppBar>)
      }

      <div className={classes.content_wrapper} ref={(container) => this.container = container}></div>
      {
        !!mobile && this.props.candlesticks_loading && (<React.Fragment>
          <CircularProgress color="white" style={{
              position: "absolute",
              left: 15,
              top: 15,
              zIndex: 999
            }} size={20}></CircularProgress>
        </React.Fragment>)
      }
    </React.Fragment>);
  }
}

const styles = theme => ({
  toolbar: {
    justifyContent: "space-between"
  },
  header_title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
});

export default Binder({
  modules: [
    "width", "theme", "windowsize", "router"
  ],
  styles,
  state: (state) => ({session: state.session, price: state.price, positions: state.positions, candlesticks: state.candlesticks.candlesticks, candlesticks_loading: state.candlesticks.loading}),
  dispatchers: dispatch => ({
    startPricePolling: _params => {
      dispatch(startPricePolling(_params));
    },
    startPositionsPolling: _params => {
      dispatch(startPositionsPolling(_params));
    },
    startCandlesticksPolling: _params => {
      dispatch(startCandlesticksPolling(_params));
    }
  }),
  // databases: []
})(ChartPage);
