import PouchDB, {
  STREAM_PATH as PATH
} from '../lib/PouchDB';

import _ from "underscore";

export const CANDLESTICKS = 'CANDLESTICKS';
export const ADD_CANDLESTICKS = 'ADD_CANDLESTICKS';
export const ADD_CANDLESTICK = 'ADD_CANDLESTICK';
export const FINISHED_LOADING = 'FINISHED_LOADING';

let candlesticks_listener = null;

export const startPolling = ({
  exchange,
  pair,
  time,
  type
}) => {

  let total = 10000;
  // let steps = 1000;

  let view = null;
  let database = null;
  let change_path = null;
  let include_docs = false;
  let orig_path = change => ({
    time: change.value.t / 1000,
    open: change.value.o,
    close: change.value.c,
    high: change.value.h,
    low: change.value.l
  })

  if (type === "candlesticks") {
    database = "kaibot-candlesticks";
    view = `${exchange}/${pair}-${time}`;
    change_path = (change) => ({
      time: change.doc.stamp / 1000,
      open: change.doc.orig.open,
      close: change.doc.orig.close,
      high: change.doc.orig.high,
      low: change.doc.orig.low
    });
  } else if (type === "heikins") {
    total = 5000;
    // steps = 500;
    include_docs = true;
    database = "kaibot-candlesticks";
    view = `${exchange}/${pair}-${time}`;
    orig_path = (change) => ({
      time: change.doc.stamp / 1000,
      open: change.doc.o,
      close: change.doc.c,
      high: change.doc.h,
      low: change.doc.l
    });
    change_path = (change) => ({
      time: change.doc.stamp / 1000,
      open: change.doc.o,
      close: change.doc.c,
      high: change.doc.h,
      low: change.doc.l
    });
  } else {
    total = 50000;
    // steps = 5000;
    database = "kaibot-blocks";
    view = `${exchange}/${pair}`;
    change_path = (change) => ({
      time: change.doc.stamp / 1000,
      open: change.doc.o,
      close: change.doc.c,
      high: change.doc.h,
      low: change.doc.l
    });
  }



  const _db = new PouchDB(`${PATH}${database}`, {
    skip_setup: true
  });

  return (dispatch, getState) => {
    dispatch({
      type: CANDLESTICKS,
      payload: [],
      seq: null
    });

    if (!!candlesticks_listener) {
      candlesticks_listener.cancel();
    }

    const doPoll = (since) => {
      candlesticks_listener = _db.changes({
        since,
        live: true,
        include_docs: true,
        batch_size: 100,
        view
      }).on('change', (change) => {
        // console.log(change);
        dispatch({
          type: ADD_CANDLESTICK,
          payload: change_path(change),
          seq: change.seq
        });
      }).on('error', (err) => {
        // handle errors
      });
    }

    _db.query(view, {
      include_docs,
      reduce: false,
      limit: total,
      descending: true,
      update_seq: true
    }).then((b) => {

      dispatch({
        type: CANDLESTICKS,
        payload: _.map(b.rows.reverse(), change => (orig_path(change))),
        seq: b.update_seq
      });

      _.defer(() => {
        doPoll(b.update_seq);

        dispatch({
          type: FINISHED_LOADING
        });
      });

    }).catch('error', (err) => {
      // this.setState({loaded: true})
    });

    // const get_candles = (skip) => {
    //   _db.query(view, {
    //     include_docs,
    //     reduce: false,
    //     limit: Math.max(Math.min(total - skip, steps), 0),
    //     skip,
    //     descending: true,
    // update_seq: true
    //     // sort: "stamp desc"
    //   }).then((b) => {
    //     if (skip === 0) {
    //       dispatch({
    //         type: CANDLESTICKS,
    //         payload: _.map(b.rows.reverse(), change => (orig_path(change)))
    //       });
    //
    //       candlesticks_listener = _db.changes({
    //         since: 'now',
    //         live: true,
    //         include_docs: true,
    //         view,
    //         // filter: (doc, req) => {
    //         //   return doc.pair === this.state.pair
    //         // }
    //       }).on('change', (change) => {
    //         added += 1; // added ondertussen
    //         dispatch({
    //           type: ADD_CANDLESTICK,
    //           payload: change_path(change)
    //         });
    //       }).on('error', (err) => {
    //         // handle errors
    //       });
    //     } else {
    //       dispatch({
    //         type: ADD_CANDLESTICKS,
    //         payload: _.map(b.rows.reverse(), change => (orig_path(change)))
    //       });
    //     }
    //
    //     let next_step = skip + steps + added;
    //
    //     if (b.rows.length === steps) {
    //       get_candles(next_step);
    //     } else {
    //       console.log("finished loading candles");
    //       dispatch({
    //         type: FINISHED_LOADING
    //       });
    //     }
    //   }).catch('error', (err) => {
    //     // this.setState({loaded: true})
    //   });
    // }
    //
    //
    // get_candles(0);


  };
}

const candlesticks = (state = {
  loading: true,
  candlesticks: [],
  seq: null
}, action) => {
  if (!!action) {
    switch (action.type) {
      case CANDLESTICKS:
        return {
          ...state,
          candlesticks: action.payload,
          seq: action.seq
        };
      case ADD_CANDLESTICK:
        return {
          ...state,
          candlesticks: [...state.candlesticks, action.payload],
          seq: action.seq
        };
      case ADD_CANDLESTICKS:
        return {
          ...state,
          candlesticks: [...action.payload, ...state.candlesticks],
          seq: action.seq
        }; // _.sortBy(, "t");
      case FINISHED_LOADING:
        return {
          ...state,
          loading: false
        };
      default:
        return state
    }
  }
};
export default candlesticks;
