import PouchDB, {
  STREAM_PATH as PATH
} from '../lib/PouchDB';

const DB = "kaibot-prices";

export const PRICE = 'PRICE';

let price_listener = null;

export const startPolling = ({
  exchange,
  pair
}) => {
  const _db = new PouchDB(`${PATH}${DB}`, {
    skip_setup: true
  });
  const tag = `${exchange}_${pair}`;

  return (dispatch, getState) => {
    if (!!price_listener) {
      price_listener.cancel();
    }

    const {
      price
    } = getState();

    price_listener = _db.changes({ // 1 record dus changes vanaf 0 met include docs om enkel de laatste change te hebben / hack :-p
      since: 0, // (!!price && !!price[tag] ? price[tag].seq : 0),
      live: true,
      include_docs: true,
      // batch_size: 100,
      view: `filtered/${exchange}`,
      filter: (doc, req) => {
        return doc.pair === pair
      }
    }).on('change', (change) => {
      // console.log(JSON.stringify(change));
      dispatch({
        type: PRICE,
        // tag,
        // seq: change.seq,
        price: (change.doc.price)
      });
    }).on('error', (err) => {
      // handle errors
    });

  };
}

const price = (state = null, action) => {
  if (!!action) {
    switch (action.type) {
      case PRICE:
        const {
          tag,
          price,
          seq
        } = action;
        return price;
        // { ...state,
        //   [tag]: {
        //     price,
        //     seq
        //   }
        // };
      default:
        return state
    }
  }
};
export default price;
