/**
Kais mega Binder
**/

import React, {Component, Fragment} from 'react';
import {withWidth} from '@material-ui/core';
import {withStyles, withTheme} from '@material-ui/styles';
import {compose} from 'redux'
import {connect} from 'react-redux';
import windowSize from 'react-window-size';
import PouchDB, {PATH} from './PouchDB';
import {withRouter} from "react-router-dom";
import _ from "underscore";

export default(options) => {
  return WrappedComponent => {
    let _options = options || {};
    let _modules = _options.modules || [];

    const Main = ({
      fref,
      ...props
    }) => {
      let _container = null;

      // const _db = new PouchDB(`${PATH}dummy`, {skip_setup: true});
      //
      // if (!!options.databases && !!_.isArray(options.databases)) {
      //   const _dbs = {};
      //
      //   _.each(options.databases, (_key) => {
      //     _dbs[_key] = new PouchDB(`${PATH}${_key}`, {skip_setup: true});
      //   })
      //
      //   _container = (<WrappedComponent {...props} ref={fref} databases={_dbs}/>);
      // } else {
      _container = (<WrappedComponent {...props} ref={fref}/>);
      // }

      return _container;
    }

    const _state = _options.state || ((state, props) => ({})); // ...props
    const _dispatchers = _options.dispatchers || ((dispatch) => ({}));

    const composers = [];

    if (!!options.styles) {
      composers.push(withStyles(options.styles));
    }

    if (_modules.indexOf("theme") > -1) {
      composers.push(withTheme);
    }

    if (_modules.indexOf("router") > -1) {
      composers.push(withRouter);
    }

    if (_modules.indexOf("width") > -1) {
      composers.push(withWidth());
    }

    if (_modules.indexOf("windowsize") > -1) {
      composers.push(windowSize);
    }

    composers.push(connect(_state, _dispatchers, null, {
      forwardRef: true,
      pure: !_options.force_update
    }));

    const ConnectedMain = compose(...composers)(Main);

    return React.forwardRef((props, fref) => (<ConnectedMain {...props} fref={fref}></ConnectedMain>))
  }
}
