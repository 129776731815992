import { combineReducers } from 'redux';
import price from './Price';
import positions from './Positions';
import candlesticks from './Candlesticks';
import session from './Session';

export default combineReducers({
  session,
  price,
  positions,
  candlesticks
});
