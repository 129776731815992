import React, {Component} from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import classNames from 'classnames';
import {Route, Switch, Redirect} from "react-router-dom";

import ChartPage from './pages/ChartPage';
import {Typography} from '@material-ui/core';

import Binder from "../lib/Binder";

import {setSession} from "../models/Session";

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged_in: false,
      loading: true
    }

    this.render_logged_in = this.render_logged_in.bind(this);
    this.render_not_logged_in = this.render_not_logged_in.bind(this);
    this.render_loading = this.render_loading.bind(this);
  }

  componentDidMount() {

    this.props.setSession(()=>{
      this.setState({loading: false})
    });

    // this.props.getSession((response) => {
    //   this.props.setSession(!!response.userCtx && !!response.userCtx.name ? response.userCtx : null);
    //   this.setState({loading: false})
    // });
  }

  componentDidUpdate(prevProps) {}

  render_logged_in() {
    const {classes} = this.props;
    return (<React.Fragment>

      <div className={classes.content}>
        <Switch>
          <Route path="/:type/:exchange/:pair/:time?">
            <ChartPage></ChartPage>
          </Route>
          <Route path="*">
            <Typography>{"Not found, try: /{{type}}/{{exchange}}/{{pair}}/{{time}}"}</Typography>
          </Route>
        </Switch>

      </div>
    </React.Fragment>);
  }

  render_not_logged_in() {
    const {classes} = this.props;
    return (<React.Fragment>
      <div className={classes.content}>

        <div className={classes.page_wrapper}>
          <Typography>{"You are not allowed here!!!"}</Typography>
        </div>

      </div>
    </React.Fragment>);
  }

  render_loading() {
    const {classes} = this.props;
    return (<React.Fragment>
      <div className={classes.content}>

        <div className={classes.page_wrapper}>
          <Typography>{"Loading..."}</Typography>
        </div>

      </div>
    </React.Fragment>);
  }

  render() {
    const {classes, session} = this.props;

    return (<React.Fragment>
      <CssBaseline></CssBaseline>
      <div className={classes.root}>
        {!this.state.loading && !!this.props.session && this.render_logged_in()}
        {!this.state.loading && !this.props.session && (this.render_not_logged_in())}
        {!!this.state.loading && this.render_loading()}
      </div>
    </React.Fragment>);
  }
}

const styles = theme => ({
  root: {
    height: "100%"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%"
  },
  page_wrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1
  }
});

export default Binder({
  modules: ["database"],
  styles,
  state: (state) => ({session: state.session}),
  dispatchers: dispatch => ({
    setSession: _params => {
      dispatch(setSession(_params));
    }
  }),
  // databases: [] // for getsession
})(Main);
