import PouchDB from 'pouchdb'
import Auth from 'pouchdb-authentication'
import Find from 'pouchdb-find'

// const PATH = "https://bot.kaiiserni.com:6984/";
export const STREAM_PATH = "https://bot.kaiiserni.com:6984/";
export const PATH = STREAM_PATH;
// export const PATH = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://localhost:5984/" : STREAM_PATH;

export default PouchDB
  .plugin(Auth)
  .plugin(Find);
