import PouchDB, {
  PATH
} from '../lib/PouchDB';

import _ from "underscore";

const DB = "kaibot-positions";

export const POSITIONS = 'POSITIONS';
export const CHANGE_POSITION = 'CHANGE_POSITION';
export const CLEAR_POSITIONS = 'CLEAR_POSITIONS';

let positions_listener = null;

let limit = 100;

export const startPolling = ({
  exchange,
  pair,
  investor
}) => {


  return async (dispatch, getState) => {
    dispatch({
      type: CLEAR_POSITIONS
    });


    let _db_name = "kaibot-positions";
    let _view = `${exchange}/${pair}`;

    if (!!investor) {
      _db_name = `userdb-${Buffer.from(investor, 'utf8').toString('hex')}`;
      _view = `positions/${exchange}_${pair}`;
    }

    let _db = new PouchDB(`${PATH}${_db_name}`, {
      skip_setup: true
    });


    if (!!investor) {

      const filters = {
        "all": {
          "map": "function (doc) {\n  if(doc.type === \"position\"){\n    emit(doc._id, doc.active);\n  }\n}"
        },
        [`${exchange}_${pair}`]: {
          "map": `function (doc) {\n  if(doc.pair === \"${pair}\" && doc.exchange === \"${exchange}\" && doc.type === \"position\"){\n    emit(doc._id, doc.active);\n  }\n}`
        }
      };

      try {
        let filter = null;
        try {
          filter = await _db.get("_design/positions");
        } catch (e) {};
        const response = await _db.put({
          _id: "_design/positions",
          _rev: !!filter ? filter._rev : null,
          views: { ...filters,
            ...(!!filter ? filter.views : {})
          },
          language: "javascript"
        });
      } catch (err) {
        console.log("Error creating views", err);
      }
    }

    const doPoll = (since) => {
      positions_listener = _db.changes({
        since,
        live: true,
        include_docs: true,
        view: _view,
        // filter: (doc, req) => {
        //   return doc.pair === pair
        // }
      }).on('change', (change) => {

        dispatch({
          type: CHANGE_POSITION,
          payload: _.extend(change.doc, {
            key: change.id
          })
        });

      }).on('error', (err) => {
        // handle errors
      });
    }

    if (!!positions_listener) {
      positions_listener.cancel();
    }

    let last_seq = null;

    const loadPositions = (startkey) => {
      _db.query(_view, {
        include_docs: true,
        reduce: false,
        descending: true,
        update_seq: true,
        limit: limit + 1,
        startkey
      }).then((b) => {

        last_seq = last_seq || b.update_seq;

        if (b.rows[limit]) {
          loadPositions(b.rows[limit].key);
        } else {
          doPoll(last_seq);
        }

        dispatch({
          type: POSITIONS,
          payload: _.map(b.rows, (row) => _.extend(row.doc, {
            key: row.key
          }))
        });

      }).catch('error', (err) => {
        // this.setState({loaded: true})
      });
    }

    loadPositions();



  };
}

const positions = (state = {}, action) => {
  if (!!action) {
    switch (action.type) {
      case POSITIONS:
        const _positions = {};
        _.each(action.payload, (p) => {
          _positions[p.key] = p;
        });
        return { ...state,
          ..._positions
        };
      case CLEAR_POSITIONS:
        return null;
      case CHANGE_POSITION:
        return {
          ...state,
          [action.payload.key]: action.payload
        };
      default:
        return state
    }
  }
};
export default positions;
