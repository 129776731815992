import React, {Suspense} from 'react';
import {Provider} from 'react-redux'
import logger from 'redux-logger'
import {createStore, applyMiddleware, compose} from 'redux';
import {persistStore, persistReducer} from "redux-persist";
import {PersistGate} from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom';
import Main from './components/Main';
import './css/app.css';
import * as serviceWorker from './serviceWorker';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {BrowserRouter} from "react-router-dom";
import rootReducer from './models';

import Colours from "./lib/Colors";

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   main: Colours.main,
    // },
    type: 'dark'
  },
  mixins: {
    toolbar: {
      height: 64,
      backgroundColor: Colours.toolbar
    }
  }
});

const initial_state = {};

const enhancers = [];
const middleware = [thunk];

if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
  middleware.push(logger);
}

const persist_config = {
  key: 'root',
  storage,
  blacklist: ["session", "candlesticks", "positions", "price"]
};

const persisted_reducer = persistReducer(persist_config, rootReducer);

const composed_enhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(persisted_reducer, initial_state, composed_enhancers);
const persistor = persistStore(store);

ReactDOM.render(<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <Suspense fallback={<div> Loading ...</div>}>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <Main theme={theme}></Main>
        </MuiThemeProvider>
      </BrowserRouter>

    </Suspense>
  </PersistGate>
</Provider>, document.getElementById('root'));

serviceWorker.unregister();
